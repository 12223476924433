var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$apollo.loading
    ? _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.$t("settings.plugins.pluginList.name")))
                  ]),
                  _c("hr"),
                  _c("p", { staticClass: "mb-5" }, [
                    _vm._v(_vm._s(_vm.$t("settings.plugins.pluginList.desc")))
                  ])
                ]
              )
            ],
            1
          ),
          _vm.folderId !== null
            ? _c(
                "b-row",
                { staticClass: "mb-5" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "override-children",
                          class: _vm.toggleAllChildrenPluginOverride
                            ? "expanded"
                            : ""
                        },
                        [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "px-4 py-3 m-0 text-uppercase d-flex justify-content-between align-items-center"
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.folder.childrenOverride.label"
                                    )
                                  )
                                )
                              ]),
                              _c("b-form-checkbox", {
                                attrs: {
                                  id: "toggle-all-children-plugin-override",
                                  name: "toggle-all-children-plugin-override",
                                  size: "lg",
                                  value: true,
                                  "unchecked-value": false
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.toggleOverrideAllChildrenBox(
                                      $event
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.toggleAllChildrenPluginOverride,
                                  callback: function($$v) {
                                    _vm.toggleAllChildrenPluginOverride = $$v
                                  },
                                  expression: "toggleAllChildrenPluginOverride"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: { id: "collapse-override-plugin-children" }
                            },
                            [
                              _c("div", { staticClass: "px-4 py-3" }, [
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "settings.folder.childrenOverride.desc"
                                      )
                                    )
                                  }
                                }),
                                _c("ul", { staticClass: "pl-0" }, [
                                  _c(
                                    "li",
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            id: "override-all-children-plugins",
                                            name:
                                              "override-all-children-plugins",
                                            size: "lg",
                                            value: true,
                                            "unchecked-value": false
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.overrideAllChildren(
                                                $event
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.allChildrenPlugins,
                                            callback: function($$v) {
                                              _vm.allChildrenPlugins = $$v
                                            },
                                            expression: "allChildrenPlugins"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s("All Plugins") + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "ul",
                                        _vm._l(
                                          _vm.allChildrenPluginsArray,
                                          function(plugin) {
                                            return _c(
                                              "li",
                                              { key: plugin.handle },
                                              [
                                                plugin.handle ===
                                                "plugin_password_protection"
                                                  ? _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          id: plugin.handle,
                                                          name: plugin.handle,
                                                          size: "lg",
                                                          disabled: true,
                                                          value: true,
                                                          "unchecked-value": false
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.changeOverrideChildren(
                                                              plugin.handle,
                                                              $event
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .overrideChildrenPlugins[
                                                              plugin.handle
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.overrideChildrenPlugins,
                                                              plugin.handle,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "overrideChildrenPlugins[plugin.handle]"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                `settings.plugins.${plugin.name}.name`
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          id: plugin.handle,
                                                          name: plugin.handle,
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.changeOverrideChildren(
                                                              plugin.handle,
                                                              $event
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .overrideChildrenPlugins[
                                                              plugin.handle
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.overrideChildrenPlugins,
                                                              plugin.handle,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "overrideChildrenPlugins[plugin.handle]"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                `settings.plugins.${plugin.name}.name`
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showPluginSectionAnalyticsAndTracking
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                    [
                      _c("h4", { staticClass: "mb-4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "settings.plugins.pluginList.nameTrackingSection"
                            )
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showPluginSectionAnalyticsAndTracking
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                    [
                      _c(
                        "b-row",
                        [
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_GOOGLE_ANALYTICS"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-google-analytics", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_GOOGLE_ANALYTICS"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_google_analytics
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_google_analytics"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_MATOMO_ANALYTICS"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-matomo-analytics", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_MATOMO_ANALYTICS"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_matomo_analytics
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_matomo_analytics"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_GOOGLE_TAG_MANAGER"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-google-tag-manager", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_GOOGLE_TAG_MANAGER"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_google_tag_manager
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_google_tag_manager"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_GOOGLE_TAG_MANAGER_TEF"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-google-tag-manager-tef", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_GOOGLE_TAG_MANAGER_TEF"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData
                                          .plugin_google_tag_manager_tef
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_google_tag_manager_tef"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_PLAUSIBLE"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-plausible", {
                                    staticClass: "pb-4 mb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges("CAN_EDIT_PLAUSIBLE")
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_plausible
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_plausible"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_FACEBOOK_PIXEL"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-facebook-pixel", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_FACEBOOK_PIXEL"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_facebook_pixel
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_facebook_pixel"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_LINKEDIN_PIXEL"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-linkedin-pixel", {
                                    staticClass: "pb-4 mb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_LINKEDIN_PIXEL"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_linkedin_pixel
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_linkedin_pixel"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showPluginSectionDataPrivacy
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-4",
                      attrs: { cols: "12", "offset-sm": "1", sm: "10" }
                    },
                    [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("settings.plugins.pluginList.nameDataPolicy")
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showPluginSectionDataPrivacy
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                    [
                      _c(
                        "b-row",
                        [
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_COOKIES_CONSENT"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-cookies-consent", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_COOKIES_CONSENT"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_cookies_consent
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_cookies_consent"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_COOKIEBOT"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-cookie-bot", {
                                    staticClass: "pb-4 mb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges("CAN_EDIT_COOKIEBOT")
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_cookie_bot
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_cookie_bot"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_CCM19"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-ccm19", {
                                    staticClass: "pb-4 mb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges("CAN_EDIT_CCM19")
                                        ) || _vm.isSysAdmin,
                                      "plugin-data": _vm.pluginData.plugin_ccm19
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_ccm19"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_USERCENTRICS"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-usercentrics", {
                                    staticClass: "pb-4 mb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_USERCENTRICS"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_usercentrics
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_usercentrics"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showPluginSectionOther
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-4",
                      attrs: { cols: "12", "offset-sm": "1", sm: "10" }
                    },
                    [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("settings.plugins.pluginList.nameOthers")
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showPluginSectionOther
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                    [
                      _c(
                        "b-row",
                        [
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_PASSWORD_PROTECTION"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-password-protection", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_PASSWORD_PROTECTION"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData
                                          .plugin_password_protection
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_password_protection"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_EXIT_INTENT"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-exit-intent", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_EXIT_INTENT"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_exit_intent
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_exit_intent"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_FLOATING_ICON_MENU"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-floating-icon-menu", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_FLOATING_ICON_MENU"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_floating_icon_menu
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_floating_icon_menu"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_INTRO_PLUGIN"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-intro", {
                                    staticClass: "pb-4 mb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_INTRO_PLUGIN"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data": _vm.pluginData.plugin_intro
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_intro"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_LEAD_MODAL"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-lead-modal", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges("CAN_EDIT_LEAD_MODAL")
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_lead_modal,
                                      "child-page-slugs": _vm.childPageSlugs
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_lead_modal"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_VARIABLES_PLUGIN"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-variables", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_VARIABLES_PLUGIN"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_variables
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_variables"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_GATED_CONTENT_HUBSPOT"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-gated-content", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges(
                                            "CAN_EDIT_GATED_CONTENT_HUBSPOT"
                                          )
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_gated_content
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_gated_content"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_DISQUS"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-disqus", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges("CAN_EDIT_DISQUS")
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_disqus
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_disqus"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_123_CHAT"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-one-two-three-chat", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges("CAN_EDIT_123_CHAT")
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_123_chat
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_123_chat"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_WEGLOT"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-weglot", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges("CAN_EDIT_WEGLOT")
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_weglot
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_weglot"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$hasPrivileges(
                            _vm.$privileges("CAN_USE_CHATGPT"),
                            _vm.isSysAdmin,
                            _vm.privileges
                          )
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("plugin-chatGPT", {
                                    staticClass: "pb-3",
                                    attrs: {
                                      "edit-privilege":
                                        _vm.privileges.includes(
                                          _vm.$privileges("CAN_EDIT_CHATGPT")
                                        ) || _vm.isSysAdmin,
                                      "plugin-data":
                                        _vm.pluginData.plugin_chatGPT
                                    },
                                    on: {
                                      "update-plugin-data": function($event) {
                                        return _vm.updatePluginData(
                                          $event,
                                          "plugin_chatGPT"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }